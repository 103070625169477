<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
    >
      <!-- banner -->
      <div class="banner-box">
        <div
          class="search-box"
          @click="goSearch"
        >
          限时活动
          <div class="icon"></div>
        </div>

        <van-swipe
          class="swiper"
          :autoplay="4500"
          @change="onChange"
        >
          <template v-for="(item,index) in bannerList">
            <van-swipe-item
              :key="index"
              class="swiper-item"
            >
              <img
                :src="item.img"
                alt=""
              >
            </van-swipe-item>
          </template>
          <template #indicator>
            <div class="custom-indicator box">
              <template v-for="(item,index) in bannerList">
                <div
                  :key="item.bannerNo"
                  class="swiper-indicator"
                  :class="{'active-indicator': index === current}"
                >
                </div>
              </template>
            </div>
          </template>
        </van-swipe>
      </div>
      <!-- banner end -->

      <!-- 分类 -->
      <div class="type-box">
        <van-grid
          :border="false"
          :column-num="4"
        >
          <van-grid-item
            v-for="(item, index) of showClassifyList"
            :key="index"
            @click="goType(index, item)"
          >
            <div
              v-if="index <= 7"
              class="type-item"
            >
              <template v-if="index === 7">
                <!-- <img src="https://test.tjerong.cn/images/org/1626429952356.png"> -->
                <img src="https://erong-qtj.oss-cn-beijing.aliyuncs.com/icons/quanbu.png" />
                <p>全部</p>
              </template>
              <template v-else>
                <img :src="item.img" />
                <p>{{ item.classifyName }}</p>
              </template>
            </div>
          </van-grid-item>
        </van-grid>
      </div>

      <div
        class="active-img box"
      >
        <van-image :src="info.activityImg"></van-image>
      </div>
      <div class="box">
        <!-- 小程序跳转 -->
        <!-- <wx-open-launch-weapp
          id="launch-btn"
          username="gh_97e32ba29a20"
          path="pages/index/index?user=123&action=abc"
        >
          <script type="text/wxtag-template">
            <style>.btn { padding: 12px }</style>
            <button class="btn">打开小程序</button>
          </script>
        </wx-open-launch-weapp> -->

        <!-- <wx-open-launch-weapp
          id="launch-btn"
          username="gh_97e32ba29a20"
          path="pages/index/index?user=123&action=abc"
        >
          <template>
            <style>.btn { padding: 12px }</style>
            <button
              class="btn"
              style="width: 100%;min-height: 50px;"
            >
              打开小程序
            </button>
          </template>
        </wx-open-launch-weapp>
        <script>
          var btn = document.getElementById('launch-btn');
          btn.addEventListener('launch', function (e) {
          console.log('success');
          });
          btn.addEventListener('error', function (e) {
          console.log('fail', e.detail);
          });
        </script>

        <wx-open-launch-weapp
          id="launch-btn1"
          username="gh_97e32ba29a20"
          path="pages/index/index"
        >
          <script type="text/wxtag-template">
            <style>.btn { padding: 12px }</style>
            <button class="btn box" style="width: 100%;min-height: 50px;">打开小程序</button>
          </script>
        </wx-open-launch-weapp>
        <script>
          var btn1 = document.getElementById('launch-btn1');
          btn1.addEventListener('launch', function (e) {
          console.log('success');
          });
          btn1.addEventListener('error', function (e) {
          console.log('fail', e.detail);
          });
        </script> -->
      </div>

      <!-- 列表 -->
      <div class="list-box box">
        <div class="list-title">
          门店列表
          <div
            class="more-btn"
            @click="goType(1, {})"
          >
            更多
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="list-in-box box">
          <div
            v-for="item in schoolList"
            :key="item.schoolNo"
            class="item-box box"
            @click="goSchoolDetail(item)"
          >
            <div class="img-box">
              <van-image :src="item.img">
                <template #error>
                  图片加载失败
                </template>
              </van-image>
            </div>
            <div class="text-a">
              <div
                v-show="item.activity == 1"
                class="tag-a tag"
              >
                平台活动
              </div>
              <!-- <div class="tag-b tag">门店活动</div> -->
              <div class="text">
                {{ item.schoolName }}
              </div>
            </div>
            <div class="text-b box">
              <div class="tag-c">
                {{ item.classifyName }}
              </div>
              <div class="text">
                {{ item.distance == -1 ? '未知' : (item.distance || '0') + 'km' }}
              </div>
            </div>
            <div class="addr-box">
              <van-icon
                class="location-icon"
                name="location"
                style="color:#51B464"
              />
              {{ item.addr || '--' }}
            </div>
          </div>
          <div
            v-show="loading"
            class="end-text box"
          >
            加载中...
          </div>
          <div
            v-show="finished"
            class="end-text box"
          >
            没有更多了
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      current: 0,
      bannerList: [],
      info: {},
      classifyList: [],
      showClassifyList: [],
      pageSize: 10,
      pageNo: 1,
      schoolList: [],
      isLoading: false,
      loading: false,
      total: 0,
      finished: false
    }
  },
  computed: {
  },
  created () {
    // var btn = document.getElementById('launch-btn');
    // btn.addEventListener('launch', function (e) {
    //   console.log('app success', e);
    // });
    // btn.addEventListener('error', function (e) {
    //   console.log('app fail', e.detail);
    // });
    this.getBannerInfo()
    let vm = this;
    vm.getSchoolList()
    try {
    // wx.ready(function(){
    //   // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    // });
      console.log('进入')
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          window.localStorage.setItem('latitude', latitude)
          window.localStorage.setItem('longitude', longitude)
          console.log(res, '成功')
        },
        fail (res) {
          conole.log(res, '失败')
        },
        complete: function (res) {
          console.log(res, 'res')
          vm.getSchoolList()
        }
      });
    } catch (e) {
      console.log(e)
      vm.getSchoolList()
    }
  },
  methods: {
    getLocation () {
    },
    onChange(index) {
      this.current = index;
    },
    async getSchoolList () {
      let vm = this
      if (!this.loading) {
        this.loading = true;
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          lat: window.localStorage.getItem('latitude') || '',
          lng: window.localStorage.getItem('longitude') || ''
        }
        this.pageNo ++
        try {
          let res = await this.$http.homeApi.schoolList(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.schoolList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.schoolList = arr
            this.total = res.msg.total
            this.finished = this.schoolList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    async getBannerInfo () {
      let res = await this.$http.homeApi.bannerInfo({})
      if (res.code === 200) {
        console.log(res, 'res')
        let data = res.msg || {}
        this.info = data
        this.bannerList = data.bannerList
        this.classifyList = data.classifyList
        this.showClassifyList = data.classifyList.slice(0,8)
        // this.$store.commit('setClassifyList', data.classifyList)
        try {
          window.localStorage.setItem('homeClassifyList', JSON.stringify(data.classifyList))
        } catch (e) {
          console.log(e)
        }
      }
    },
    async onRefresh () {
      await this.getBannerInfo()
      this.schoolList = []
      this.pageNo = 1
      await this.getSchoolList()
      this.isLoading = false
    },
    goType (index, item) {
      this.$router.push(`/home/type?index=${index}&classifyNo=${item.classifyNo || ''}`)
    },
    goSchoolDetail (item) {
      this.$router.push(`/school/detail?id=${item.schoolNo || ''}`)
    },
    // 处理滚动条滚到底部加载数据
    getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        if (this.pageNo * this.pageSize <= this.total) {
          this.getSchoolList()
        }
      }
    },
    goSearch () {
      this.$router.push('/home/search')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 5px;
  .banner-box {
    width: 100%;
    height: 215px;
    position: relative;
    .search-box {
      width: 346px;
      height: 34px;
      background: rgba(167, 173, 185, 0.4);
      border-radius: 17px;
      color: #fff;
      font-size: 13px;
      line-height:34px;
      text-align: center;
      position: absolute;
      left: 50%;
      margin-left: -173px;
      top: 24px;
      z-index: 99;
      .icon {
        position: absolute;
        right: 12px;
        top: 6px;
        width: 20px;
        height: 20px;
        background: url(../../assets/img/hr-search.png) center center no-repeat;
        background-size: 100% 100%;
      }
    }
    .swiper {
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;
      line-height: 215px;
      .swiper-item {
        img {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
      .custom-indicator {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        height: 20px;
        line-height: 14px;
        cursor: pointer;
        .swiper-indicator {
          width: 10px;
          height: 3px;
          display: inline-block;
          margin: 0 3px;
          background: rgb(190,190,197);
          border-radius: 1.5px;
        }
        .active-indicator {
          background: #282734;
        }
      }
    }
  }

  .active-img {
    margin-top: 2px;
    min-height: 75px;
    padding: 0 16px;
    .van-image {
      width: 100%;
      height: 75px;
    }
  }

  .type-box {
    text-align: center;
    img {
      width: 42px;
      height: 42px;
      display: inline-block;
    }
    p {
      font-size:14px;
      color:#5a6a7e;
      line-height:20px;
    }
  }

  .list-box {
    padding: 5px 0;
    .list-title {
      padding: 0 16px;
      line-height: 30px;
      font-size:17px;
      font-weight:bold;
      .more-btn {
        position: absolute;
        right: 11px;
        top: 0;
        color: #9EA5AD;
        padding: 5px;
        font-size: 12px;
        justify-content: center;
        i {
          top: 1px;
        }
      }
    }
    // .list-in-box::before,
    // .list-in-box::after {
    //   content: "";
    //   flex-basis: 100%;
    //   width: 0;
    //   order: 2;
    // }
    .list-in-box {
      width: 100%;
      padding: 6px 6px 6px 16px;
      // column-count: 2;
      .item-box {
        // break-inside: avoid;
        padding: 5px 5px 10px 5px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 166px;
        height: 246px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        .img-box {
          height: 84px;
          background: #ccc;
          img {
            width: 100%;
            height: 100%;
          }
          .van-image {
            width: 100%;
            height: 100%;
          }
        }
        .text-a {
          margin-top: 6px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 14px;
          line-height: 14px;
          .tag {
            // display: inline-block;
            margin-top:2px;
            float: left;
            line-height: 16px;
            padding: 0 6px;
            font-size: x-small;
            font-size: 10px;
            color:#fff;
            margin-right: 6px;
            border-radius: 8px;
            // margin-bottom: 2px;
          }
          .tag-a {
            background: linear-gradient(90deg, #FF953F, #FB703B);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
          }
          .tag-b {
            background: linear-gradient(-90deg, #6A42F5, #A084FF);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
          }
          .text {
            // display: inline-block;
            font-size: 15px;
            line-height: 20px;
          }
        }
        .text-b {
          margin-top:10px;
          .tag-c {
            padding: 0 4px;
            float: left;
            height: 16px;
            background: #E8F7EF;
            border-radius: 2px;
            color: #51B464;
            font-size: 12px;
            line-height:16px;

          }
          .text {
            float: right;
            line-height: 16px;
            color: #9EA5AD;
            font-size: 12px;
          }
        }
        .addr-box {
          margin-top: 10px;
          padding-left: 16px;
          font-size: 13px;
          color: #9EA5AD;
          position: relative;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          .location-icon {
            position: absolute;
            left: 1px;
            top: 3px;
            line-height:14px;
            font-size: 14px;
            color: #51B464;
          }
        }
      }
      // .item-box:nth-child(2n+1) { margin-right: 10px; }
      // .item-box:nth-child(2n) { }
    }
    // 
    // @media only screen and (min-width: 650px) {
    //   .list-in-box {
    //     column-count: 3;
    //   }
    // }
  }
}
</style>
